.modalContainer {
    z-index: 100000;
    display: flex;
    width: 100vw;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(20, 43, 97, 0.5);
}

.scrollLocked {
    overflow-y: hidden !important;
}
