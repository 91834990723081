@import "./../../css_global.scss";

.miniBox{
    cursor: pointer;
    padding:3px;
    display: inline-block;
    opacity: 0.7;
    border-bottom: none;
    text-decoration: none;

    &:hover {
        opacity:1;
        color:rgba(139,0,0,1);

        & > .miniBox_X {
            @include transition(500ms);
            & > path { fill: rgba(139,0,0,1) }
        }

    }
}

abbr[title], acronym[title] {
    border-bottom: none;
    text-decoration: none;
}

.miniBox_X{
    position: relative;
    width: 14px;
    margin-left:5px;
    top:2px;
    & > path { @include transition(500ms);fill: rgba(0,0,0,0.4) }
}