@import "./../../css_global.scss";


.filterZone {

    @include transition(500ms);
    z-index: 2;
    position: absolute;
    display: flex;
    background-color: $colorForeground;
    width: 0px;
    height: 100vh;
    right: 0px;
    top:0px;
    overflow-x: hidden;
    white-space: nowrap;

    &.active {
        width: 500px; right:-500px;
        @media screen and (max-width:$bpS) {
            width: calc(100vw - 200px);
            right: calc(-1 * (100vw - 200px));
        }
    }

}

.additionalFilterBehind{
    @include transition(500ms);
    position: fixed;
    z-index: 1;
    top:0px; left:250px;
    width: calc(100% - 250px);
    height: 100%;
    background-color: rgba(255,255,255,0.8);
    backdrop-filter: blur(3px);
    cursor: pointer;
}

.filterZone2 {
    
    position: relative;
    color:white;
    width: 100%;
    overflow-x: hidden;
    min-height: calc(100vh - 50px);
    padding-bottom:100px;
    box-sizing: border-box;
    overflow: hidden;

    &> div:first-of-type {
        padding-top:100px;
    }

    @media screen and (max-width:$bpS) { width: calc( 0.95 * (100vw - 200px)); }
    @media screen and (max-width:$bpXS) { width: 100%; }

    

}


.filterZone3{
    height: calc(100% - 90px);
    width: 500px;
    white-space: normal;
    // min-height: calc(100vh - 70px);





    overflow-y: auto;

    // CHROME
    --scrollbarBG: rgba(0,0,0,0);
    --thumbBG: white;
    
    // FIREFOX
    scrollbar-width: thin;
    scrollbar-color: rgba(255,255,255,0) rgba(255,255,255,1);

    // CHROME SAFARI EDGE
    &::-webkit-scrollbar {width: 20px;}
    // &::-webkit-scrollbar-track {margin-left: 40px;}
    // &:hover::-webkit-scrollbar-track { margin-top: 20px; }

    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 7px solid rgba(0,0,0,0);
        background-clip: padding-box;
        background-color: white;
        cursor: pointer;
    }
}


//----------------------------------------------------------------------------
// FILTER ATTR
//----------------------------------------------------------------------------
.filterAtt{
    position: relative;
}
.filterAtt_title{
    position: sticky;
    z-index: 2;
    padding-left:50px;
    top:-100px;
    min-height: 50px;
    display:flex; align-items: center;
    background-color: $colorForeground;
}
.filterAtt_easyBar{
    z-index: 1;

}
.filterAtt_content{
    z-index: 1;
    padding-left:75px;
}




//----------------------------------------------------------------------------
// BUTTON ZONE
//----------------------------------------------------------------------------



.buttonsZone{
    position: absolute;;
    bottom:0px;
    padding-left: 50px;
    padding-bottom:20px;
    
    @media screen and (max-width:$bpXS) { padding-bottom:0px; }
}
