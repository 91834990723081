@import "../../css_global.scss";

.page_parent {
  position: relative;
  overflow: unset;
}

.page_details,
.page_details_right {
  position: relative;
  width: 50%;
  display: inline-block;
  padding-right: 50px;
  box-sizing: border-box;
  vertical-align: top;
  margin-bottom: 50px;
  position: sticky;
  top: 0px;

  @media screen and (max-width: $bpS) {
    width: 100%;
    padding-right: 0px;
    display: block;
    margin-bottom: 20px;
    position: unset;
    top: unset;
  }
}

.searchListingPage_buttomButtonsArea {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.loadMoreButtonArea {
  margin-top: 25px;
  height: 75px;
  text-align: center;

  @media screen and (max-width: $bpXS) {
    margin-bottom: 100px;
  }
}

//-------------------------------------------------------------
// 		FILTERED WARNING BOX
//-------------------------------------------------------------
.dataZone .setAsFavourite {
  z-index: 99999;
  position: absolute;
  right: 50px;
  top: 50px;
  @media screen and (max-width: $bpS) {
    position: static;
    .container {
      display: block;
      margin-bottom: 2rem;
    }
  }
}
