@import "../../css_global.scss";

.search_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 50px);
  padding: 0 12rem;
  .searchBar {
    margin-bottom: 10rem;
    @media screen and (max-width: $bpS) {
      margin-bottom: 5rem;
    }
  }

  @media screen and (max-width: $bpS) {
    padding: 0 1rem;
    .searchBar {
      margin-bottom: 5rem;
    }
  }
}
