@import "./../../css_global.scss";

.footer_main {
  width: 100%;
  height: 50px;
  background-color: $colorDpBlue;
  color: white;
  display: flex;
  justify-content: space-between;

  box-sizing: border-box;
  align-items: center;

  padding-left: 75px;
  padding-right: 50px;
  &.FMloadingView {
    margin-top: 0px;
  }

  @media screen and (max-width: $bpXS) {
    width: 100vw;
    margin-left: -5vw;
    height: 40px;
    margin-top: -40px;
    padding-left: 25px;
    justify-content: flex-start;
    &.FMloadingView {
      margin-top: 0px;
    }
  }
  @media screen and (min-width: $bpXS) and(max-width:$bpS) {
    padding-left: 30px;
    padding-right: 30px;
    &.FMhomeView {
      padding-left: 125px;
      padding-right: 30px;
    }
  }
  @media screen and (min-width: $bpS) and(max-width:$bpM) {
    padding-left: 50px;
    padding-right: 40px;
    &.FMhomeView {
      padding-left: 150px;
    }
  }
}

.footer_linkarea_left {
  text-align: left;

  & > a {
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
    color: white;

    &:first-child {
      margin-left: 0px;
    }

    &:active,
    &:visited,
    &:hover,
    &:focus {
      color: white;
    }
  }

  @media screen and (max-width: $bpXS) {
    display: none;
  }
}

.footer_linkarea_right {
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > a {
    height: 30px;
    @media screen and (max-width: $bpXS) {
      height: 25px;
    }
  }
  & > span {
    color: white;
    margin-right: 10px;
  }

  @media screen and (max-width: $bpXS) {
    justify-content: flex-start;
  }
  @media screen and (min-width: $bpXS) and(max-width:700px) {
    & > span {
      display: none;
    }
  }
}

.footer_linkarea_logo {
  width: 145px;

  @media screen and (max-width: $bpXS) {
    width: 110px;
  }
}
