@import "./../../css_global.scss";

.header {
    width: 100%;
    min-height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @extend .gradient;
    background-attachment: unset;

    @media screen and (max-width: $bpXS) {
        position: relative;
        padding-top: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        min-height: unset;
        background-color: unset;
        background: unset;
    }

    // IE11 FIX
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 125px;
    }
}

.searchBoxC {
    display: flex;
    width: 90%;
}

.actionContainer {
    @media screen and (min-width: $bpXS) {
        position: absolute;
        bottom: 10px;
        right: 60px;
        float: right;
    }

    button:first-child {
        margin-right: 10px;
    }
}

.headerLine {
    width: 100%;
    height: 1px;
    margin-bottom: 5px;
    background-color: $colorGrey5;
    @media screen and (max-width: $bpXS) {
        display: none;
    }

    &.footer {
        margin-bottom: 0px;
    }
}

.MobileHeaderMedName {
    @extend .font_seperator;
    font-size: 20px;
    width: 95%;
    line-height: 1.8;
    padding-top: 20px;
    padding-bottom: 20px;
}

//----------------------------------------------------------------------------
// HORIZONTAL NAVIGATION
//----------------------------------------------------------------------------

.horizontalNav {
    position: relative;
    z-index: 3;
    width: calc(100% - 75px);
    left: 75px;
    @include transition(500ms);

    & > div {
        @extend .font_link;
        @include transition(500ms);
        text-transform: none;
        display: inline;
        cursor: pointer;
        padding-right: 20px;
        padding-left: 20px;
        user-select: none;
        position: relative;

        &:first-child {
            margin-left: -20px;
        }

        &.active {
            cursor: default;
        }

        &.active::after {
            // nav indicator
            height: 7px;
            background-color: $colorForeground;
            width: 100%;
            content: "";
            position: absolute;
            left: 0px;
            bottom: -10px;
        }

        & > a {
            @extend .font_link;
            text-transform: none;
        }
    }

    @media screen and (min-width: $bpS) and(max-width:$bpM) {
        width: calc(100% - 50px);
        left: 50px;
    }

    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        width: calc(100% - 30px);
        left: 30px;
        & > div {
            position: relative;
            padding-right: 0px;
            padding-left: 0px;
            margin-right: 15px;
            margin-left: 15px;

            &:first-child {
                margin-left: 0px;
            }

            &.active {
                cursor: default;
            }
        }
    }
    @media screen and (max-width: $bpXS) {
        position: fixed;
        bottom: 0px;
        width: 100%;
        white-space: nowrap;
        height: 40px;
        padding-top: 10px;
        left: unset;
        margin-left: -5vw;
        overflow-x: auto;
        background-color: $colorGrey2;

        & > div {
            position: relative;
            font-size: 13px;
            padding-right: unset;
            padding-left: unset;
            margin-right: 5px;
            margin-left: 5px;
            padding-bottom: 7px;
            color: white;
            margin-top: 4px;
            border-bottom: 2px solid rgba(255, 255, 255, 0);

            &:first-child {
                margin-left: 15px;
                color: white;
            }

            &.active {
                cursor: default;
                border-bottom: 2px solid rgba(255, 255, 255, 1);
                color: white;
            }

            &.active::after {
                // nav indicator
                display: none;
            }

            & > a {
                color: white;
                text-transform: none;
            }
        }
    }
}

.hoverIndicator {
    position: absolute;
    z-index: 1;
    @include transition(500ms);
    background-color: $colorGrey3;
    opacity: 0.5;
    width: 0px;
    height: 7px;
    pointer-events: none;

    // IE11 FIX
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-top: -7px;
    }
}

//----------------------------------------------------------------------------
// SEARCHBOX
//----------------------------------------------------------------------------

.searchBox {
    @include transition(500ms);
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    left: 0px;
    flex: 1;

    &.hover,
    &.focus,
    &.onChange {
        background-color: $colorGrey2;
        color: $colorBack;
    }

    @media screen and (max-width: $bpXS) {
        display: none;
    }
}

.searchBoxContainer {
    position: relative;
    width: calc(100% - 150px);
    left: 75px;
    @media screen and (min-width: $bpL) {
    }
    @media screen and (min-width: $bpM) and(max-width:$bpL) {
    }
    @media screen and (min-width: $bpS) and(max-width:$bpM) {
    }
    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        width: calc(100% - 110px);
        left: 30px;
    }
}

.searchBoxInput {
    @include transition(500ms);
    @extend .font_pageHeader;

    position: relative;
    color: $colorGrey1;
    word-wrap: break-word;
    width: 100%;
    background: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
    resize: none;
    border: 0 none;
    border-radius: 0;
    margin-left: 90px;

    border-bottom: 1px solid rgba(255, 255, 255, 0);

    &:focus {
        outline: 0;
    }

    // IE11 FIX
    &::-ms-clear {
        display: none;
    }

    &.hover,
    &.focus,
    &.onChange {
        color: $colorBack;
    }

    @media screen and (min-width: $bpL) {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 75px;
        padding-left: 170px;
        margin-left: -75px;
    }
    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 30px;
        padding-left: 25px;
        margin-left: -25px;
    }
}

.searchBoxMagnifier {
    @include transition(500ms);
    position: absolute;
    z-index: 11;
    width: 30px;
    fill: $colorGrey4;
    left: -45px;
    opacity: 0.5;
    top: 20px;
    pointer-events: none;

    &.hover,
    &.focus,
    &.onChange {
        fill: $colorBack;
        opacity: 1;
    }

    @media screen and (max-width: $bpS) {
        display: none;
    }

    //IE11 FIX
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: 4px;
    }

    &.hide {
        opacity: 0;

        &.hover,
        &.focus,
        &.onChange {
            opacity: 1;
        }
    }
}

.searchBoxLevelTag {
    @include transition(500ms);
    position: absolute;
    z-index: 11;
    width: 118px;
    background-color: $colorGrey4;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    left: -55px;
    top: 20px;
    pointer-events: none;
    opacity: 1;
    text-align: left;

    &.hover,
    &.focus,
    &.onChange {
        opacity: 0;
    }

    @media screen and (max-width: $bpS) {
        display: none;
    }

    //IE11 FIX
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: 20px;
    }
}

.searchBoxClearButton {
    @include transition(500ms);
    position: absolute;
    z-index: 11;
    display: none;
    width: 30px;
    height: 30px;
    fill: $colorGrey4;
    right: 60px;
    opacity: 0.5;
    top: 20px;
    cursor: pointer;

    & > rect {
        fill: rgba(0, 0, 0, 0);
        width: 16px;
        height: 16px;
    }

    &.hover,
    &.focus,
    &.onChange {
        display: inherit;
        fill: $colorBack;
        opacity: 1;
    }

    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        right: 50px;
        top: 17px;
    }
}

.searchBoxCancelButton {
    @include transition(500ms);
    position: absolute;
    z-index: 11;
    display: none;
    width: 30px;
    height: 30px;
    fill: $colorGrey4;
    right: 20px;
    opacity: 0.5;
    top: 20px;
    cursor: pointer;

    & > rect {
        fill: rgba(0, 0, 0, 0);
        width: 16px;
        height: 16px;
    }

    &.onChange,
    &.focus {
        display: inherit;
        fill: $colorBack;
        opacity: 1;
    }

    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        right: 10px;
        top: 17px;
    }
}

.searchBoxEnterButton {
    @include transition(500ms);
    position: absolute;
    z-index: 11;
    display: none;
    width: 28px;
    width: 28px;
    fill: $colorGrey4;
    right: 100px;
    opacity: 0.5;
    top: 22px;
    cursor: pointer;

    & > rect {
        fill: rgba(0, 0, 0, 0);
        width: 16px;
        height: 16px;
    }

    &.onChange,
    &.focus {
        display: inherit;
        fill: $colorBack;
        opacity: 1;
    }

    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        right: 90px;
        top: 17px;
    }

    //IE11 FIX
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: 4px;
    }
}

//----------------------------------------------------------------------------
// SEARCHBOX SUGGESTION ZONE
//----------------------------------------------------------------------------

.searchBoxBigBlueZone {
    @include transition(500ms);
    position: absolute;
    top: 80px;
    z-index: 5;
    background-color: $colorForeground;
    height: 0px;
    box-sizing: border-box;
    overflow-y: hidden;
    padding-left: 75px;
    padding-right: 75px;
    width: calc(100% + 75px);
    left: -75px;

    & > .searchBox_buttonZone {
        margin-top: 50px;
    }

    &.onChange {
        height: calc(100vh - 100px);
    }

    @media screen and (min-width: $bpM) and(max-width:$bpL) {
        top: 60px;
    }
    @media screen and (min-width: $bpS) and(max-width:$bpM) {
        top: 60px;
    }
    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        top: 60px;
        padding-left: 30px;
        width: calc(100% + 105px);
        left: -30px;
    }
}

.searchSuggestion_scrollableZone {
    position: relative;
    display: block;
    height: calc(100vh - 200px);
    margin-top: 50px;
    user-select: none;

    & > a {
        @extend .font_searchSuggestion;
        color: $colorBack;
    }

    overflow-x: hidden;
    overflow-y: auto;

    --scrollbarBG: rgba(0, 0, 0, 0);
    --thumbBG: #fff;

    // FIREFOX
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);

    // CHROME SAFARI EDGE
    &::-webkit-scrollbar {
        width: 20px;
    }
    // &::-webkit-scrollbar-track {
    // margin-left: 40px;
    //   }

    // &:hover::-webkit-scrollbar-track {
    // margin-top: 20px;
    // }

    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 8px solid var(--scrollbarBG);
        background-clip: padding-box;
        background-color: var(--thumbBG);
    }
}

.searchSuggestion_container {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: max-content;
    width: 100%;
}
.searchSuggestion_type {
    position: relative;
    @extend .font_text;
    color: $colorForeground;
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 20px;
    width: 50px;
    display: inline-block;
    text-align: center;
    top: 10px;
    vertical-align: top;
}
.searchSuggestion_name {
    width: calc(100% - 100px);
    position: relative;
    display: inline-block;
}
