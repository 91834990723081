.lib-checkbox {
  &.b-contain *,
  .b-contain *::before,
  .b-contain *::after {
    box-sizing: content-box !important;
  }

  &.b-contain input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &.b-contain span {
    line-height: 1.54;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 400;
  }

  &.b-contain {
    display: table;
    position: relative;
    padding-left: 1.8rem;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }

  &.b-contain input[type="checkbox"] ~ .b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background: rgba(241, 245, 248, 1);
    transition: background 250ms;
    border: 1px solid rgba(184, 194, 204, 1);
    border-radius: 0.125rem;
  }

  &.b-contain input[type="checkbox"] ~ .b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 0.45rem;
    top: 0.18rem;
    width: 0.2rem;
    height: 0.5rem;
    border: solid rgba(255, 255, 255, 1);
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
  }

  &.b-contain input:disabled ~ .b-input::after {
    border-color: rgba(135, 149, 161, 1);
  }

  &.b-contain input:checked ~ .b-input::after {
    display: block;
  }

  &.b-contain:hover input ~ .b-input,
  .b-contain input:focus ~ .b-input {
    background: rgb(231, 238, 243);
  }

  &.b-contain input:focus ~ .b-input {
    box-shadow: 0 0 0 2px rgba(52, 144, 220, 0.5);
  }

  &.b-contain input:checked ~ .b-input {
    background: rgba(72, 180, 226, 1);
  }

  &.b-contain input[type="checkbox"]:disabled ~ .b-input {
    background: rgba(241, 245, 248, 1);
    border-color: rgba(184, 194, 204, 1);
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.b-contain input:checked:focus ~ .b-input,
  .b-contain:hover input:not([disabled]):checked ~ .b-input {
    background: rgba(72, 180, 226, 1);
    border-color: rgba(72, 180, 226, 1);
  }

  &.b-contain .b-input::before {
    content: "";
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    margin-left: -0.85rem;
    margin-top: -0.85rem;
    background: rgba(0, 130, 243, 1);
    border-radius: 2rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
  }

  &.b-contain .b-input::before {
    visibility: hidden;
  }

  &.b-contain input:focus + .b-input::before {
    visibility: visible;
  }

  &.b-contain:first-child .b-input::before {
    visibility: hidden;
  }
}
