.searchFilters {
  padding: 20px 50px;

  .filtered_link {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
    & > span {
      text-decoration: none;
    }
    &:hover > span {
      text-decoration: underline;
    }
  }

  .filtered_miniBoxZone {
    height: 30px;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .invisible {
    display: none;
  }
}
