@import "./../../../css_global.scss";

.settingsTab {
  min-height: calc(100vh - 295px);
  padding: 40px 70px;

  @media screen and (max-width: $bpS) {
    padding: 20px 1.5em;
  }

  .loader {
    height: calc(100vh - 340px);
    display: grid;
    place-items: center;
  }

  .radioContainer {
    display: flex;
    margin: 15px 0;
    @media screen and (max-width: $bpS) {
      flex-direction: column;
    }
    label {
      margin: 0.5rem;
    }
    span {
      font-size: 14px;
    }
  }
  button:first-of-type {
    margin-right: 14px;
  }

  @media screen and (max-width: $bpS) {
    .flex {
      display: flex;
      flex-direction: column-reverse;
    }

    button {
      width: 100%;
      justify-content: center;
    }

    button:first-of-type {
      margin-right: 0px;
      margin-top: 1em;
    }
  }
}
