@import "../../../css_global.scss";

.modal {
  padding: 18px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  color: $colorDarkGrey;
  border-radius: 4px;
  min-width: 420px;

  @media screen and (max-width: $bpS) {
    min-width: 300px;
    margin: 0 2em;
  }

  .cancelButton {
    @media screen and (min-width: $bpS) {
      margin-right: 20px;
    }
  }
  .topBar {
    display: flex;
    justify-content: space-between;
    h3 {
      margin: 0;
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;

      @media screen and (max-width: $bpS) {
        width: 100%;
        text-align: center;
      }
    }
    svg {
      cursor: pointer;
      @media screen and (max-width: $bpS) {
        display: none;
      }
    }
  }

  .content {
    margin: 25px 0 20px 0;
    font-size: 1.1em;
    @media screen and (max-width: $bpS) {
      text-align: center;
      margin: 1em 0 1em 0;
    }
  }

  .bottomBar {
    float: right;
    button:last-of-type {
      margin-left: 20px;
    }

    @media screen and (max-width: $bpS) {
      float: none;
      display: flex;
      flex-flow: column-reverse;
      button {
        justify-content: center;
        &:last-of-type {
          margin: 0 0 1em 0;
        }
      }
    }
  }

  .customBottomBar {
    display: flex;
    justify-content: space-between;
    button {
      &:first-of-type {
        margin-left: 0;
      }
    }
    div button {
      &:last-of-type {
        margin-left: 20px;
      }
    }

    @media screen and (max-width: $bpS) {
      div {
        display: flex;
        flex-flow: column-reverse;
        button {
          &:last-of-type {
            margin: 0;
          }
          &:nth-of-type(1) {
            margin: 1em 0;
          }
        }
      }
      display: flex;
      flex-flow: column-reverse;
      button {
        justify-content: center;
        margin: 0;
      }
    }
  }
}
