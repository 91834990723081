@import "./../../../css_global.scss";

.sidebarButton {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 75px;
  width: 200px;
  float: right;
  border-right: 5px solid transparent;

  .icon {
    padding-right: 20px;
    display: grid;
    box-sizing: border-box;
    place-items: center;
  }

  h2 {
    font-weight: 600;
    font-size: 14px;
    padding-right: 25px;
    color: white;
  }

  &:hover {
    background-color: #48b4e2;
    border-right: 5px solid #89cee8;
    cursor: pointer;

    .sub-menu {
      left: 205px;
      opacity: 1;
    }
  }

  .sub-menu-item {
    width: 200px;
    height: 75px;
    background-color: #48b4e2;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: white;
    border-right: 5px solid #89cee8;
    padding-left: 25px;

    &:hover {
      background: #299bcb;
    }
  }

  .sub-menu {
    left: -125px;
    top: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;

    @for $i from 1 through 2 {
      .sub-menu-item:nth-child(#{$i}) {
        top: #{(($i - 1) * 75)}px;
      }
    }
  }
}
