@import "./../../css_global.scss";

.searchbar {
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid #ededed;
  display: inline-flex;
  align-items: center;
  padding: 5px 14px;
  justify-content: space-between;
  flex-basis: 50%;
  max-width: 560px;

  @media screen and (max-width: $bpXS) {
    display: none;
  }

  input,
  select {
    height: 100%;
    border: none;
    background: rgba(255, 255, 255, 0.6);
    &:focus {
      outline: none;
    }
  }

  input {
    padding: 0px 9px;
    width: 80%;
  }
}
