@import "./../../../css_global.scss";

.checkBox_main{
    cursor: pointer;
    @extend .fontL;
    position: relative;
    display: flex;
    align-items: baseline;
    color:white;
    font-weight: 400;
    // margin-left:30px;
    margin-top:10px;
    width: max-content;

    &.big {
        @extend .font_seperator;
        color:white;
        font-weight: 300;
        margin-left:3px;
        margin-bottom:10px;
    }
}

.checkBox_label{
    
    max-width: 350px;
    margin-left:10px;
    display: inline-block;
    user-select:none;
    white-space:break-spaces;
}

.checkBox_box{
    display: inline-block;
    position: relative;
    top:2px;
    width: 16px;
    height: 16px;
    border: 1px solid white;

    &.true::after {
        content:"";
        position: absolute;
        width: 8px;
        height: 8px;
        top:4px;
        left:4px;
        background-color: white
    }
}
