@import "./../../css_global.scss";

.notificationContainer {
  margin: 30px 50px;
}

@media screen and (max-width: $bpXS) {
  .newRightOfSidebar {
    width: auto;
    left: auto;
  }

  .footer_main {
    margin-left: auto;
  }
  .notificationContainer {
    margin: 1.5em;
  }
}
