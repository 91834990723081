.tile {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  line-height: 19px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;

  .price,
  .lastUpdated label {
    color: #48b4e2;
  }

  .discontinued {
    padding: 5px;
    float: right;
    color: white;
    background-color: maroon;
  }

  .price {
    margin-top: 14px;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
  }

  .topBar {
    display: flex;
    justify-content: space-between;

    & svg:hover {
      cursor: pointer;
    }
  }

  &:hover {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  .orange {
    color: darkorange;
  }

  .blue,
  .pipCode,
  .gtin {
    label {
      color: #48b4e2;
    }
  }

  .blue {
    color: #48b4e2;
  }

  .logo {
    float: right;
  }
}
