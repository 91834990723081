@import "../../../css_global.scss";
.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.5em 1em;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  min-height: 36px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
  &.blue {
    background: $colorLakeBlue;
    &:hover {
      background: #299bcb;
    }
  }
  &.transparent {
    background: transparent;
    color: $colorDarkGrey;
    border: 1px solid $colorDarkGrey;
  }

  &.white {
    background: transparent;
    color: white;
    border: 1px solid white;
  }

  &.withIcon {
    padding: 0.5em 0.8em;
    svg {
      margin-right: 0.2em;
    }
  }
}
