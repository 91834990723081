@import "../../css_global.scss";

.isLoading_Zone {
    @extend .font_pageHeader;

    width: 100vw;
    min-height: 100vh;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 10;
    background-color: $colorGrey2;
    color: $colorBack;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.isLoading_loadingSVG {
    width: 48px;
    display: block;

    & > g > circle {
        fill: white;
    }

    // IE11 FIX
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 13px;
    }
}
