@import "./../../css_global.scss";

/*
    Overriding OneTrust 'Cookie Setting' style
    Note: '!important' is required here to ensure that this style will be used
          OneTrust's native style is always rendered at the bottom part of the <head> tag
*/
.ot-sdk-show-settings {
    color: white!important;
    border: 0!important;
    padding: 0!important;
    font-size: 12.8px!important;
    line-height: 1!important;
    white-space: nowrap!important;
    cursor: pointer!important;

    &:active, &:visited, &:hover, &:focus { 
        color:white!important;
        background-color: transparent!important; 
    }

    @media screen and (max-width: $bpM) { font-size: 12px!important; }
    @media screen and (max-width: $bpS) { font-size: 11px!important; }
}

#ot-sdk-btn-mobile.ot-sdk-show-settings {
    padding-left: 50px!important;
    @extend .font_mobileLink;
    line-height: 3!important;
    font-size: 20px!important;
    @media screen and (max-width: $bpM) { font-size: 18px!important; }
    @media screen and (max-width: $bpS) { font-size: 16px!important; }
}