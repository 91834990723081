@import "../../css_global.scss";

.searchBoxHome_container{
    display: block;
    position: relative;
}

.searchBoxHome_magnifier{
    
    @include transition(500ms);
    position: absolute;
    z-index:2;
    width: 30px;
    fill:$colorGrey4;
    opacity: 0.5;
    left:0px;
    top:36px;
    pointer-events: none;

    // IE11 FIX
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { top:15px; }

}
.searchBoxHome_input{

    @include transition(500ms);
    @extend .font_pageHeader;
    position: relative;
    z-index:1;
    width: 100%;
    height: 50px;
    margin-top: 25px;

    color:$colorGrey1;
    word-wrap: break-word;

    background: transparent;
    resize: none;
    border: 0 none;
    border-radius: 0;
    padding-left:50px;
    box-sizing: border-box;
    
    border-bottom: 1px solid $colorGrey4;
    
    &:focus {
        outline:0;

        & ~ .searchBoxHome_magnifier {
            opacity: 1;
        }

    }

    &:placeholder-shown ~ .searchBoxHome_enterButton {
        opacity:0;
    }


    //IE11 FIX
    &::-ms-clear { display: none; }


}
.searchBoxHome_enterButton{

    @include transition(500ms);
    position: absolute;
    z-index:2;
    width: 28px;
    fill:$colorGrey4;
    right: 0px;
    opacity: 1;
    top:37px;
    cursor: pointer;
    
    & > rect {
        fill:rgba(0,0,0,0);
        width: 16px;
        height: 16px;
    }

    @media screen and (max-width:$bpS) { display:none;}

    // IE11 FIX
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { top:15px; }

}
.homepage-search-dropdown{
    .select-box--container {
        height: 30px;
        border: none;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      
      .select-box--box {
        width: 200px;
        position: absolute;
        outline: none;
        scroll-behavior: smooth;
        color: #22213a;
        font-weight: 300;
        border: none !important;
        background-color: transparent;
        z-index: 2;
        left: 43%;
        top: 29px;
        @media screen and (max-width: 1024px) {
          z-index: 2;
          left: 0;
          top: 10px;
          font-size: 17px;
          color: #1a1a2b;
          position: relative;
        }
      }
      
      .select-box--selected-item {
        display: inline-block;
        height: 100%;
        width: 100%;
        vertical-align: middle;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        @media screen and (max-width: 1024px)
        {
          font-size: 14px;
        }
      }
      
      .select-box--items {
        margin-top: 10px;
        cursor: pointer;
        font-weight: 500;
        @media screen and (max-width: 1024px)
        {
          margin-top: 0px;
        }
        div {
          border-bottom: one;
          border-left: none;
          border-right: none;
          padding: 0px;
          font-size: 14px;
        }
      }
      
      .select-box--arrow {
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        display: inline-block;
        position: absolute;
        right: 30px;
        cursor: pointer;
        @media screen and (max-width: 1024px)
        {
          top: 9px;
        }
      }
      
      .select-box--arrow-down {
        position: absolute;
        left: 10px;
        top: 17px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 10px solid #22213a;
        @media screen and (max-width: 1024px)
        {
          top: 12px;
        }
        @media screen and (max-width: 640px)
        {
          left: 175px;
        }
        @media screen and (max-width: 400px)
        {
          left: 130px;
        }
      }
      
      .select-box--arrow-up {
        position: absolute;
        left: 10px;
        top: 15px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid #22213a;
        @media screen and (max-width: 1024px)
        {
          top: 10px;
        }
        @media screen and (max-width: 640px)
        {
          left: 175px;
        }
        @media screen and (max-width: 400px)
        {
          left: 130px;
        }
      }
      .invisible{
        display: none;
    }
}
.search-btn{
  display: none;
  @media screen and (max-width: 500px)
  {
    display: inherit;
    top: 60px;
    position: relative;
    font-size: 18px;
    border: 1px solid grey;
    border-radius: 3px;
    text-align: center;
    padding: 5px 10px;
    font-weight: 700;
  }
  &:hover{
    background-color: #313131;
    color:white;
  }
}