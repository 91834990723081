.newMobileMenu {
  position: relative;
  z-index: 100;
  height: 100vh;
  background: #606060;
  padding: 2em;

  a {
    display: block;
    text-decoration: none;
    color: white;
    font-weight: 300;
    // text-transform: uppercase;
    font-size: 2em;
  }

  h1,
  svg {
    color: white;
  }

  .subCategory {
    background: #454545;
    margin: 2em -2em;
    h1,
    a {
      margin: 0 1em;
    }
  }
}
