@import "./../../css_global.scss";
.renderBoxes_main {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  & > .boxes_maincontainer {
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-block;
    border-radius: $globalRadius;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0);
    @include transition(500ms);

    &:hover {
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.4);
    }
  }

  // full page sized cards
  @media screen and (min-width: 1300px) {
    & > .boxes_maincontainer {
      width: calc((99% - 80px) / 4);
    }
  }
  @media screen and (min-width: 1000px) and(max-width:1300px) {
    & > .boxes_maincontainer {
      width: calc((99% - 60px) / 3);
    }
  }
  @media screen and (min-width: 700px) and(max-width:1000px) {
    & > .boxes_maincontainer {
      width: calc((99% - 40px) / 2);
    }
  }
  @media screen and (max-width: 700px) {
    & > .boxes_maincontainer {
      width: calc((99%) / 1);
    }
  }

  &.half {
    // half page sized cards for level pages
    @media screen and (min-width: 1300px) {
      & > .boxes_maincontainer {
        width: calc((100% - 40px) / 2);
      }
    }
    @media screen and (max-width: 1300px) {
      & > .boxes_maincontainer {
        width: calc((100%) / 1);
      }
    }
  }

  & > .boxes_maincontainer > a {
    width: 100%;
    color: $colorGrey2;
    text-decoration: none;
  }
}

.box_seperator {
  width: 100%;
  min-height: 75px;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.boxes_container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 100%;
}

.SUP_container {
  @extend .boxes_container;
  padding-top: 20px;
  padding-bottom: 20px;
}

.VMP_head1st {
  width: 100%;
}

.VMP_IdCell {
  padding: 10px 20px 5px 20px;
  text-transform: uppercase;
  @include transition(500ms);

  & > span {
    @include transition(500ms);
    color: $colorForeground;
  }
}

.VMP_nameCell {
  @extend .font_supTextL;
  padding: 5px 20px 5px 20px;
  // @include transition(500ms);
  // width:100%;
  flex-grow: 2;
}

.VMP_routeCell {
  padding: 5px 20px 10px 20px;
  color: $colorForeground;
  @include transition(500ms);
  width: 100%;
}

.AMP_3rdCell {
  padding: 5px 20px 10px 20px;
  @include transition(500ms);
  width: 100%;
}

.AMP_codeLine {
  & > span {
    color: $colorForeground;
  }
}

.boxes_maincontainer {
  position: relative;
}

.externalURLicon {
  position: absolute;
  width: 68px;
  height: 20px;
  right: 20px;
  top: 12px;
  cursor: pointer;
  @include transition(500ms);
  // opacity:0.8;

  // &:hover { opacity:1 }

  & > .externalURLicon_emblem {
    fill: #69bd9f;
  }
  & > .externalURLicon_type {
    fill: #212d5f;
  }
}

.AMPP_priceLine {
  @extend .font_mobileLink;
  font-weight: 500;
  line-height: 2;
  color: $colorForeground;
}

.AMPP_price_discArea {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AMPP_availability {
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  width: fit-content;
  color: white;
  background-color: maroon;
}

//----------------------------------------------------------------------------
// RENDER ACC CSSs
//----------------------------------------------------------------------------

.renderDetails_main {
  margin: 50px;
  @media screen and (max-width: $bpXS) {
    margin: 50px 0px;
  }
}

.renderDetails_acc {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.renderDetails_mainLabel {
  width: 100%;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.disable {
    cursor: default;
  }
}

.renderDetails_mainLabel_collapseAll {
  width: 40px;
  position: absolute;
  cursor: pointer;
  user-select: none;
  margin-top: -4px;

  &.disable {
    cursor: default;
  }
}

.renderDetails_iconArea {
  width: 50px;
  display: inline-block;
  vertical-align: top;
  margin-top: -4px;
}

.renderDetails_AccCollapseIcon {
  width: 26px;
  height: 26px;
  @media screen and (max-width: $bpXS) {
    margin-left: 15px;
  }
}

.renderDetails_AccCollapseIcon2 {
  // collapseAll
  width: 26px;
  height: 26px;
  @media screen and (max-width: $bpXS) {
    margin-left: 15px;
  }
}

.renderDetails_LabelArea {
  @extend .font_seperator;
  margin-top: 3px;
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top;
  user-select: none;
  outline: none;
}

//----------------------------------------------------------------------------
// EXTENDABLE ZONE
//----------------------------------------------------------------------------

.renderDetails_extendableZone {
  width: 100%;
  @include transition(500ms);
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.2s ease-out;
}

//----------------------------------------------------------------------------
// DISPLAY ITEMS with LINES
//----------------------------------------------------------------------------

.dataZone_container {
  position: relative;
  display: flex;

  &.emcUrlbuttonFix {
    height: 100px;
    margin-bottom: 50px;
    // margin-top:-10px;
  }

  @media screen and (max-width: $bpXS) {
    display: block;

    &.emcUrlbuttonFix {
      height: unset;
      margin-top: unset;
    }
  }
}

.dataZone_label {
  width: 250px;
  display: inline-block;
  text-align: right;
  padding-top: 20px;
  vertical-align: top;

  @media screen and (min-width: $bpM) and(max-width:$bpL) {
    width: 200px;
  } // 1400-1600
  @media screen and (min-width: $bpS) and(max-width:$bpM) {
    width: 150px;
  } // 1000-1400
  @media screen and (min-width: $bpXS) and(max-width:$bpS) {
  } // 600-1000
  @media screen and (max-width: $bpXS) {
    text-align: left;
    display: block;
    width: 100%;
    font-weight: 700;
  }
}

.dataZone_line {
  position: relative;
  display: inline-block;
  width: 50px;
  height: auto;
  text-align: center;

  & > .dataZone_line_yatay {
    position: absolute;
    width: 20px;
    height: 1px;
    background-color: $colorGrey4;
    left: 15px;
    top: 33px;
  }
  & > .dataZone_line_dikey {
    position: relative;
    width: 1px;
    height: 100%;
    left: 24.5px;
    top: 0px;
    background-color: $colorGrey4;
  }

  & > .emcUrlbutton_yatay {
    position: absolute;
    display: none;
    width: 20px;
    height: 1px;
    background-color: $colorGrey4;
    left: 15px;
    top: 70px;
  }
  & > .emcUrlbutton_dikey {
    position: relative;
    display: none;
    width: 1px;
    height: 70px;
    left: 24.5px;
    top: 0px;
    background-color: $colorGrey4;
  }

  @media screen and (max-width: $bpXS) {
    display: none;
  } // 600 altı
}

.dataZone_value {
  width: calc(100% - 300px); // don't forget media queries
  padding-top: 20px;
  display: inline-block;

  &.emcUrlbuttonFix {
    margin: auto;
  }

  @media screen and (min-width: $bpM) and(max-width:$bpL) {
    width: calc(100% - 250px);
  } // 1400-1600
  @media screen and (min-width: $bpS) and(max-width:$bpM) {
    width: calc(100% - 200px);
  } // 1000-1400
  @media screen and (min-width: $bpXS) and(max-width:$bpS) {
  } // 600-1000
  @media screen and (max-width: $bpXS) {
    display: block;
    width: 100%;
    padding-top: unset;
  } // 600 altı
}

.dataZone_link {
  // text-decoration: none;
  color: $colorGrey1;
}

//----------------------------------------------------------------------------
// DISPLAY subSections with LINES
//----------------------------------------------------------------------------

.dataZone_subSection_title {
  margin-top: 30px;
  margin-left: 70px;
  @extend .fontL;
  font-weight: 700;

  @media screen and (max-width: $bpXS) {
    margin-left: unset;
  }
}

//----------------------------------------------------------------------------
// FIXING first and last lines which is between label and data.
//----------------------------------------------------------------------------
.renderDetails_items {
  & > div:first-child > .dataZone_line > .dataZone_line_dikey {
    top: 33px;
    height: calc(100% - 33px);
  }
  & > div:last-child > .dataZone_line > .dataZone_line_dikey {
    top: 0px;
    height: 33px;
  }
  & > div:first-child:last-child > .dataZone_line > .dataZone_line_dikey {
    display: none;
  }
}
.renderDetails_items_subSections {
  & > div > div:nth-child(2) > .dataZone_line > .dataZone_line_dikey {
    top: 33px;
    height: calc(100% - 33px);
  }
  & > div > div:last-child > .dataZone_line > .dataZone_line_dikey {
    top: 0px;
    height: 33px;
  }
  & > div:first-child:last-child > .dataZone_line > .dataZone_line_dikey {
    display: none;
  }
}

//----------------------------------------------------------------------------
// Market ATT Section Styles
//----------------------------------------------------------------------------
.marketAtt_style {
  position: relative;
  margin-left: 50px;
  padding: 30px;
  width: 300px;
  background-color: white;
  border-radius: $globalRadius;
  box-sizing: border-box;
  @media screen and (max-width: $bpXS) {
    margin-left: unset;
    width: 100%;
  }
}
.marketAtt_available {
  padding: 3px 10px 3px 10px;
  background-color: green;
  color: white;
  width: max-content;
  display: inline-block;
}
.marketAtt_unavailable,
.marketAtt_discontinued {
  @extend .marketAtt_available;
  background-color: darkred;
}
.marketAtt_price {
  @extend .fontXXL;
  font-weight: 300;
  line-height: 1;
  // color:$colorForeground;
  color: #bf7c1a;
  vertical-align: middle;
}

.scpack-ampp-style > span {
  color: darkorange !important;
}
