/*
@media screen and (max-width:$bpXS) { } // 600 altı
@media screen and (min-width:$bpXS)and(max-width:$bpS) { } // 600-1000
@media screen and (min-width:$bpS)and(max-width:$bpM) { } // 1000-1400
@media screen and (min-width:$bpM)and(max-width:$bpL) { } // 1400-1600
@media screen and (min-width:$bpL) { } // 1600 üzeri

@media screen and (max-width:600px) { }
@media screen and (min-width:600px)and(max-width:1000px) { }
@media screen and (min-width:1000px)and(max-width:1400px) { }
@media screen and (min-width:1400px)and(max-width:1600px) { }
@media screen and (min-width:1600px) { }


*/



//----------------------------------------------------------------------------
// Global Variables
//----------------------------------------------------------------------------

$bpMAX:4000px;
$bpL:1600px;
$bpM:1400px; // between 1000 - 1400
$bpS:1000px; // between 0 - 1000
$bpXS:600px;

$globalRadius: 15px;

$colorBack: #F4F5F9; // we're using this one if gradient doesn't work 
$colorGrey1:#454545; // darkest WCAG 7.0 69,69,69,1
$colorGrey2:#606060; // WCAG 4.6  96,96,96,1
$colorGrey3:#7D7D7D; // lightest WCAG 3.0  125,125,125,1
$colorGrey4:#909090; // 144,144,144,1
$colorGrey5:#cccccc; // 204,204,204,1
$colorDpBlue:#0C2761; // 12,39,97,1
$colorForeground:#48b4e2; // 72,180,226,1



//----------------------------------------------------------------------------
// PREFIX PACKAGES
//----------------------------------------------------------------------------

@mixin transition($degree) { //@include transition(500ms); 
  transition: all $degree ease;
  -webkit-transition: all $degree ease;
  -moz-transition: all $degree ease;
  -o-transition: all $degree ease;
}


//----------------------------------------------------------------------------
// Global Gradient
//----------------------------------------------------------------------------

.gradient {
  
    background-color: $colorBack;
    // background: linear-gradient(145deg, rgb(245, 245, 249) 0%, rgb(220, 220, 220) 100%);
    background: linear-gradient(145deg, rgb(255, 255, 255) 0%, rgb(240, 240, 240) 70%);
    background-attachment: fixed;
  
}

//----------------------------------------------------------------------------
// Global FontSize
//----------------------------------------------------------------------------

.fontXS {
    font-size: 10px;
    @media screen and (max-width: $bpM) { font-size: 10px; }
    @media screen and (max-width: $bpS) { font-size: 9.5px; }
}

.fontS {
    font-size: 12.8px;
    @media screen and (max-width: $bpM) { font-size: 12px; }
    @media screen and (max-width: $bpS) { font-size: 11px; }
}

.fontM {
    font-size: 14px;
    @media screen and (max-width: $bpM) { font-size: 13px; }
    @media screen and (max-width: $bpS) { font-size: 12px; }
}

.fontL {
    font-size: 16px;
    @media screen and (max-width: $bpM) { font-size: 15px; }
    @media screen and (max-width: $bpS) { font-size: 14px; }
}

.fontXL {
    font-size: 20px;
    @media screen and (max-width: $bpM) { font-size: 18px; }
    @media screen and (max-width: $bpS) { font-size: 16px; }
}

.fontXXL {
    font-size: 36px;
    @media screen and (max-width: $bpM) { font-size: 32px; }
    @media screen and (max-width: $bpS) { font-size: 28px; }
}


//----------------------------------------------------------------------------
// Global FontStyles
//----------------------------------------------------------------------------

.font_XS_text{ font-weight: 500; color:$colorGrey2; @extend .fontXS; line-height: 2; text-transform: none; text-decoration:none;}
.font_text{ font-weight: 500; color:$colorGrey2; @extend .fontS; line-height: 2; text-transform: none; text-decoration:none;}
.font_supText{ font-weight: 500; color:$colorGrey2; @extend .fontM; line-height: 2; text-transform: none; text-decoration:none;}
.font_supTextL{ font-weight: 500; color:$colorGrey2; @extend .fontM; line-height: 2; text-transform: none; text-decoration:none;}
.font_title{ font-weight: 700; color:$colorGrey2; @extend .fontM; line-height: 1.1; text-transform: none; text-decoration:none;}
.font_lefttitle{ font-weight: 700; color:$colorGrey2; @extend .fontM; line-height: 1.1; text-transform: uppercase; text-decoration:none;}
.font_link{ font-weight: 500; color:$colorGrey2; @extend .fontM; line-height: 1.1; text-transform: uppercase; text-decoration:none;}
.font_mobileLink{ font-weight: 300; color:white; @extend .fontXL; line-height: 3; text-transform: uppercase; text-decoration:none;}
.font_boldlink{ font-weight: 700; color:$colorGrey2; @extend .fontM; line-height: 1.1; text-transform: uppercase; text-decoration:none;}
.font_button{ font-weight: 700; color:$colorGrey2; @extend .fontM; line-height: 1.1; text-transform: uppercase; text-decoration:none; }
.font_seperator{ font-weight: 400; color:$colorGrey2; @extend .fontXL; line-height: 1; text-transform: none; text-decoration:none;} // Condensed page title (header titles) are also using this one.
.font_pageHeader{ font-weight: 300; color:$colorForeground; @extend .fontXL; line-height: 0.9; text-transform: none; text-decoration:none;}
.font_searchSuggestion{ font-weight: 300; color:$colorBack; @extend .fontXL; line-height: 2; text-transform: none; text-decoration:none;}


// Firefox Font Fix
@-moz-document url-prefix() { .font_XS_text { font-weight: 300 } .font_text { font-weight: 300 } .font_supText { font-weight: 300 } .font_link { font-weight: 300 } }

// Edge Font Fix
@supports (-ms-ime-align:auto) { .font_XS_text { font-weight: 300 } .font_text { font-weight: 300 } .font_supText { font-weight: 300 } .font_link { font-weight: 300 } }

// IE Font Fix
.font_XS_text {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { font-weight: 300;} }
.font_text {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { font-weight: 300;} }
.font_supText {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { font-weight: 300;} }
.font_link {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { font-weight: 300;} }

    ////////////////////////////////////////////////////

$colorNavy: #142b61;
$colorLakeBlue: #48b4e2;
$colorLightBlue: #89cee8;
$colorOrange: #FF9540;
$colorDarkGrey: #606060;
$colorLightGrey: #EDEDED;
$colorBackgroundGrey: #F0F0F0;

$success: #D0F8C0;
$info: #EEFAFF;
$warning: #FFE3AB;
$error: #F8C0C0;

$success-dark: #67B599;
$info-dark: #48B4E2;
$warning-dark: #FF9540;
$error-dark: #AF000D;