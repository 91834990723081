@import "../../../css_global.scss";
.notification {
  display: flex;
  align-items: center;
  padding: 0.7em 1.4em;
  border: 2px solid;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  text-align: left;

  span {
    margin-left: 0.7em;
  }

  &.success {
    background: $success;
    border-color: $success-dark;
  }

  &.error {
    background: $error;
    border-color: $error-dark;
  }

  &.warning {
    background: $warning;
    border-color: $warning-dark;
  }

  &.info {
    background: $info;
    border-color: $info-dark;
  }
}
