@import "./../../../css_global.scss";


.filterSection_titleArea{
    cursor: pointer;
    width: 100%;
    height: 40px;
    margin-top: 5px;
    position: relative;
    display: flex;
    align-items: center;
    user-select:none;
}
.filterSection_iconArea{
    width: 30px;
    height: 24px;
    display: inline-block;
}
.filterSection_title{
    display: inline-block;
    @extend .font_seperator;
    color:white;
    font-weight: 300;
    user-select:none;

    &>span{
        @extend .fontS;
        color:white;
    }
}
.filterSection_collapsableArea{
    width: 100%;
    @include transition(150ms);
    overflow: hidden;
    max-height: 0px;
}

.currentContent{
    width: 100%;
}

.filterSections_PlusSVG{
    width: 24px;
}


.filterSection_easyFindBar{
    position: relative;
    z-index:1;
    width: calc(100% - 30px);
    // margin-left:30px;
    margin-top:10px;
    margin-bottom:20px;
    height: 40px;
    color:white;
    word-wrap: break-word;

    background: rgba(0,0,0,0);
    resize: none;
    border: 0 none;
    border-radius: 0;
    border-bottom: 1px solid white;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */ color: white; opacity: 1; }
    &:-ms-input-placeholder { /* IE 10-11 */ color: white; }
    &::-ms-input-placeholder { /* MSEdge */ color: white; }
    
    
    
    &:focus {
        outline:0;

        & ~ .searchBoxHome_magnifier {
            opacity: 1;
        }

    }

    &:placeholder-shown ~ .searchBoxHome_enterButton {
        opacity:0;
    }
}

.filterSection_lines{
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(255,255,255,0);
    @include transition(500ms);

    &.disable {
        background-color: rgba(255,255,255,0);
    }
}