@import "./../../css_global.scss";


.MobileSearchZone{
    position:relative;
    margin-top:100px;
    width: 90vw;
    margin-left:5vw;
}

.MobileSearchZone_input{

    @extend .font_seperator;
    font-size:20px;
    line-height: 1.8;
    color:white;
    width: 90%;
    background: transparent;
    resize: none;
    border-radius: 0;
    box-sizing: border-box;
    padding-bottom:25px;
    padding-left:10px;

    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

}