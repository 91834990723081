@import "./../../../css_global.scss";

.footerButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  border-top: 5px solid transparent;
  height: 50px;
  padding: 0 10px;

  &:hover {
    cursor: pointer;
    background: #48b4e2;
    border-color: #89cee8;
    .sub-menu {
      left: 0px;
      opacity: 1;
    }
  }
  .sub-menu-item {
    position: relatiave;
    width: 200px;
    height: 75px;
    background-color: #48b4e2;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: white;
    padding-left: 25px;

    &:hover {
      background: #299bcb;
    }
  }

  .sub-menu {
    bottom: 50px;
    left: -356px;
    opacity: 0;
    position: absolute;
    z-index: 1;

    @for $i from 1 through 2 {
      .sub-menu-item:nth-child(#{$i}) {
        bottom: #{(($i - 1) * 75)}px;
      }
    }
  }
}
