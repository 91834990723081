@import "../../css_global.scss";

.errorPages {
    position: relative;
    margin: auto;
    min-height: 70vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width: $bpL) {
        width: 70%;
    }
    @media screen and (min-width: $bpM) and(max-width:$bpL) {
        width: 75%;
    }
    @media screen and (min-width: $bpS) and(max-width:$bpM) {
        width: 80%;
    }
    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        width: 85%;
    }
    @media screen and (max-width: $bpXS) {
        width: 95%;
    }
}

.errorpage_LogoFull {
    position: relative;
    display: unset;
    display: block;
    width: 250px;
    padding-top: 75px;
    padding-bottom: 30px;
    pointer-events: none;

    & > .skyBlueZone {
        fill: $colorForeground;
    }
    & > .darkBlueZone {
        fill: $colorDpBlue;
    }

    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        width: 200px;
    }
    @media screen and (max-width: $bpXS) {
        width: 175px;
    }
}

.errorpage_container {
    margin-left: 45px;
    line-height: 2.5;

    @media screen and (max-width: $bpXS) {
        padding-right: 20px;
    }
    @media screen and (max-width: $bpS) {
        margin-left: 20px;
    }
}

.br_Mobile {
    // under 600
    display: none;
    @media screen and (max-width: $bpXS) {
        display: block;
    }
}

.br_onlyTablet {
    // 600 - 1000
    display: none;
    @media screen and (min-width: $bpXS) and(max-width:$bpS) {
        display: block;
    }
}

.br_overTablet {
    // over 600
    display: none;
    @media screen and (min-width: $bpXS) {
        display: block;
    }
}

.br_underTablet {
    // under 1000
    display: none;
    @media screen and (max-width: $bpS) {
        display: block;
    }
}

.br_Desktop {
    // over 1000
    display: none;
    @media screen and (min-width: $bpS) {
        display: block;
    }
}
.review-text {
    font-weight: 600;
    font-size: 14px;
}

.home_title {
    @extend .font_seperator;
}

.home_link {
    text-decoration: none;
    color: $colorGrey1;
    &:hover {
        text-decoration: underline;
    }
    &:active,
    &:visited,
    &:focus {
        color: $colorGrey1;
    }
}

.errorpage_buttonArea {
    margin-top: 40px;
}
