@import "./../../css_global.scss";

.newHeader {
  padding: 40px 70px;
  display: flex;

  .mobile {
    display: none;
  }

  h3,
  .profileDropdown {
    flex-grow: 1;
    margin: 0;
    font-weight: 600;
    font-size: 18px;
  }

  .queryContainer {
    flex-grow: 4;
    display: flex;
    justify-content: space-around;
    align-items: center;

    h5 {
      margin: 0;
      font-weight: normal;
      font-size: 14px;
    }
  }
  .closeIcon {
    padding: 5vw 5vw;
  }

  .searchIcon {
    padding: 0 1em;
  }
}

@media screen and (max-width: $bpXS) {
  .newHeader {
    padding: 20px;
    flex-direction: column;

    .queryContainer,
    .title {
      display: none;
    }

    .mobile {
      display: block;

      .LogoFull {
        padding: 0;
      }

      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .titleContainer {
        margin-top: 4em;
        .actions {
          font-weight: bold;
          position: relative;

          &.open {
            color: white;
            z-index: 100;
          }

          .mobileButtons {
            position: absolute;
            top: 4em;
            right: 0;
            div {
              background: #ffffff;
              color: #454545;
              padding: 0.5em 1em;
              box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
              border-radius: 4px;
              margin-bottom: 1em;
              width: max-content;
              float: right;
            }
          }
          h3 {
            font-weight: 600;
          }
        }

        .backdrop {
          position: absolute;
          z-index: 50;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(20, 43, 97, 0.5);
        }
      }
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      width: max-content;
    }
  }
}
