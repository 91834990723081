@import "./../../../css_global.scss";

.tileTab {
  min-height: calc(100vh - 285px);

  .loader {
    height: calc(100vh - 247px);
    display: grid;
    place-items: center;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin: 0 50px 70px 50px;

    @media screen and (max-width: $bpM) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: $bpS) {
      gap: 1.5em;
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $bpXS) {
      margin: 0 1.5em;
      grid-template-columns: 1fr;
    }
  }

  .noContent {
    height: calc(100vh - 215px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    svg {
      color: $colorLakeBlue;
      font-size: 10em;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 3em;
    .gototop {
      display: none;
    }
    @media screen and (max-width: $bpXS) {
      margin: 1.5em 0 65px 0;

      .gototop {
        display: inline-flex;
        margin-left: 1.5em;
      }
    }
  }
}
