@import "./../../../css_global.scss";
.searchResults {
  min-height: calc(100vh - 295px);
  .searchResultsTab,
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin: 70px 50px 70px 50px;

    @media screen and (max-width: $bpM) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: $bpS) {
      gap: 1.5em;
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $bpXS) {
      margin: 20px 1.5em;
      grid-template-columns: 1fr;
    }
  }

  i {
    margin: 0 50px;
  }
}
