
.header-search-dropdown{
      .select-box--box {
        width: 200px;
        position: absolute;
        outline: none;
        scroll-behavior: smooth;
        color: #22213a;
        border: none !important;
        background-color: transparent;
        z-index: 2;
        left: 15%;
        top: 15px;
        @media screen and (max-width: 1024px) {
          z-index: 2;
          left: 0;
          top: 10px;
          font-size: 17px;
          color: #1a1a2b;
          position: relative;
        }
        .select-box--container {
            height: 30px;
            border: none;
            width: 100%;
            margin: 0;
            padding: 0;
            .select-box--selected-item {
                display: inline-block;
                height: 100%;
                width: 100%;
                vertical-align: middle;
                font-size: 16px;
                font-weight: 500;
                @media screen and (max-width: 1024px)
                {
                  font-size: 14px;
                }
              }
              .select-box--arrow {
                width: 30px;
                height: 30px;
                margin: 0;
                padding: 0;
                display: inline-block;
                position: absolute;
                right: 30px;
                @media screen and (max-width: 1024px)
                {
                  top: 9px;
                }
                .select-box--arrow-down {
                    position: absolute;
                    left: 10px;
                    top: 12px;
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 10px solid #22213a;
                } 
                .select-box--arrow-up {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 10px solid #22213a;
                }
                .select-box--items {
                margin-top: 10px;
                cursor: pointer;
                font-weight: 500;
                @media screen and (max-width: 1024px)
                {
                  margin-top: 0px;
                }
                .invisible{
                    display: none;
                }
                div {
                  border-bottom: one;
                  border-left: none;
                  border-right: none;
                  padding: 0px;
                  font-size: 14px;
                }
              }
          }
      }  
    }
    .select-box--box{
        z-index: 25;
    }
}
.invisible{
  display: none;
}