@import "./../../css_global.scss";

.navbar {
  border-bottom: 1px solid #606060;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 48px;
  align-items: flex-end;

  .tabs {
    padding: 0 70px;

    a {
      font-size: 14px;
      text-decoration: none;
      color: #606060;
      padding: 5px 15px;
      &:hover {
        border-bottom: 5px solid #606060;
      }
      &.active:hover {
        border-bottom: 5px solid #48b4e2;
      }
    }

    .active {
      color: #48b4e2;
      border-bottom: 5px solid #48b4e2;
    }
  }

  .buttons {
    align-self: flex-start;
    margin-right: 50px;
    span {
      margin: 0 0.5em;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .actions {
    padding: 0 70px;
  }

  @media screen and (max-width: $bpXS) {
    position: fixed;
    display: block;
    bottom: 0;
    background: #0c2761;
    border: none;
    width: 100%;

    .buttons {
      display: none;
    }
    .tabs {
      padding: 0;
      height: 100%;
      margin: 0 1.5em;
      overflow-x: scroll;
      overflow-y: hidden;
      a {
        height: 100%;
        box-sizing: border-box;
        color: white;
      }
    }
  }
}
