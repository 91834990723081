@import "./../../../css_global.scss";

.Button {

    position: relative;
    display: inline-flex; justify-content: center; align-items: center;
    cursor: pointer;
    user-select: none;
    width: max-content;
    height: 50px;
    padding-right: 25px;
    padding-left: 25px;
    margin-left: 10px;
    margin-right: 10px;
    @extend .font_supText;
    @include transition(500ms);

    &:first-child {
        margin-left: 0px;
    }


    &.important {
        padding-right: 50px;
        padding-left: 50px;
    }

    &.white {
        color:$colorBack;
        box-shadow:inset 0px 0px 0px 1px $colorBack;

        &:hover {
            background-color: $colorBack;
            color:$colorForeground;
            box-shadow:inset 0px 0px 0px 3px $colorForeground;
        }

        &.important {
            background-color: $colorBack;
            color:$colorForeground;
        }
    }

    &.grey {
        color:$colorGrey2;
        box-shadow:inset 0px 0px 0px 1px $colorGrey2;

        &:hover {
            background-color: $colorBack;
            color:$colorForeground;
            box-shadow:inset 0px 0px 0px 3px $colorForeground;
        }

        &.important {
            background-color: $colorBack;
            color:$colorForeground;
        }
    }



}
