@import "./../../css_global.scss";


//----------------------------------------------------------------------------
// ICONS
//----------------------------------------------------------------------------


.mobileMenuIconDiv {
    position: absolute;
    z-index: 9;
    top:0px;
    display: inline-flex;
    width: 90%;
    left:5%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    margin-top: 83px;
}

.mm_logo{
    position: relative;
    width: 150px;
    padding-top:25px;

    & > .skyBlueZone { fill: $colorForeground; }
    & > .darkBlueZone { fill: $colorDpBlue; }
}


























.mobileIcon{
    width: 24px;
    margin-right: 20px;
    &>path {
        fill:$colorGrey3;
    }
}

.mobileFilterIcon {
    width: 24px;
}
.mobileSearchIcon {
    margin-right: 15px;
}
.mobileMenuIcon {
    margin-right: 0px;
}


//----------------------------------------------------------------------------
// MOBILE ZONES (SEARCH & FILTER & MENU)
//----------------------------------------------------------------------------


.mobileZones {
    // @extend .font_mobileLink;
    color:$colorBack;
    @include transition(500ms);
    z-index: 1000;
    position: fixed;
    overflow: hidden;
}

.mobileZoneInner{
    width: 90vw;
    margin: auto;
    margin-top:50px;
    position: relative;

    & > a {
        @extend .font_mobileLink;
        position: relative;
        display: block;
        text-decoration: none;
        padding-left:50px;
        color:white;
    }

    @media screen and (max-width:$bpXS) {
        width: 100vw;
        margin-top:0px;

        

        & > a:first-of-type {
            margin-top:100px;
        }
    }
}


.mobileCloseIcon {
    @include transition(500ms);
    position: absolute;
    right: 0px;
    top: 20px;
    z-index: 1001;
    &>path { fill: $colorBack }
}

.Menu_Background {
    top:0px;
    right:0px;
    width: 0%;
    min-height: 100vh;
    background-color: $colorGrey2;

    &.active {
        width: 100%;
    }
}

.Filter_Background {
    top:0px;
    left:0px;
    width: 0%;
    min-height: 100vh;
    background-color: $colorForeground;
    overflow-x: hidden;
    white-space: nowrap;

    &.active {
        width: 100%;
    }

}

.Search_Background {
    top:0px;
    left:0px;
    width: 100%;
    min-height: 0vh;
    height: 0vh;
    background-color: $colorForeground;

    &.active {
        min-height: 100vh;
    }

}