@import "../../../css_global.scss";

.popup {
  padding: 18px;
  background-color: white;
  color: $colorDarkGrey;
  border-radius: 4px;
  min-width: 300px;

  @media screen and (max-width: $bpS) {
    display: flex;
    align-items: center;
  }

  .topBar {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $bpS) {
      display: none;
    }

    .flex {
      display: flex;
      align-items: center;

      svg {
        padding-right: 0.5em;
      }
    }
    h3 {
      margin: 0;
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
    }
    .closeIcon {
      cursor: pointer;
    }
  }

  .content {
    margin-top: 1em;
    @media screen and (max-width: $bpS) {
      display: inline-block;
      margin-top: 0;
      font-weight: bold;
    }
  }

  .mobileIcon {
    display: none;
    @media screen and (max-width: $bpS) {
      display: grid;
      place-items: center;
    }
  }

  &.success {
    background: $success;
  }

  &.error {
    background: $error;
  }
}
