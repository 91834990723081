@import "./../../css_global.scss";

.sidebar {
  position: fixed;
  width: 75px;
  height: 100vh;
  z-index: 2;
  @include transition(500ms);
  & > .emblemSVG {
    opacity: 0;
  }

  & > .leftLine {
    position: absolute;
    background-color: $colorGrey5;
    width: 1px;
    height: 100vh;
    right: 0px;
  }

  &:hover {
    background-color: $colorGrey2;
    width: 200px;

    .icon svg {
      fill: white;
    }
    .sub-menu {
      left: 0px;
    }
  }

  @media screen and (min-width: $bpXS) {
    &:hover {
      width: 200px;
      background-color: $colorGrey2;
      & ~ .rightOfSidebar {
        left: 200px;
      }
    }
  }

  @media screen and (max-width: $bpXS) {
    position: fixed;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    top: 15px;
    right: 0px;
    height: 50px;
    background-color: $colorBack;
    background-attachment: fixed;

    & > .leftLine {
      display: none;
    }
    & > .sidebarButtonMainDiv {
      display: none;
    }
  }
}

.rightOfSidebar {
  position: relative;
  z-index: 1;
  width: calc(100vw - 93px);
  min-height: 100vh;
  left: 75px;
  @include transition(500ms);
  @media screen and (max-width: $bpXS) {
    width: 95vw;
    left: 5vw;
    min-height: unset;
  }
}

.LogoEmblem {
  position: relative;
  display: block;
  width: 47.5px;
  height: 47.5px;
  margin: auto;
  margin-top: 25px;
  pointer-events: none;
  @include transition(500ms);

  & > path {
    fill: $colorForeground;
  }

  @media screen and (max-width: $bpXS) {
    display: none;
  }
}
.LogoType {
  position: relative;
  display: block;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 20px;
  width: 125px;
  height: 38px;
  opacity: 0;
  pointer-events: none;
  // background-color: red;
  @include transition(500ms);

  & > .LogoTypeEMC {
    fill: $colorForeground;
  }
  & > .LogoTypeDMDBROWSER {
    fill: $colorBack;
  }

  @media screen and (max-width: $bpXS) {
    display: none;
  }
}

.LogoFull {
  display: none;

  @media screen and (max-width: $bpXS) {
    position: relative;
    display: unset;
    display: block;
    width: 175px;
    padding-top: 67px;
    padding-bottom: 30px;
    pointer-events: none;

    & > .skyBlueZone {
      fill: $colorForeground;
    }
    & > .darkBlueZone {
      fill: $colorDpBlue;
    }
  }
}
