@import "../../css_global.scss";

.popupContainer {
  position: fixed;
  z-index: 1000;
  bottom: 100px;
  right: 100px;

  @media screen and (max-width: $bpS) {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
}
