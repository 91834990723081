#nav-search .nav-search-dropdown {
    position: absolute;
    display: block;
    top: -1px;
    left: 0;
    height: 35px;
    width: auto;
    font-family: inherit;
    outline: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: visible;
    border: 0;
    line-height: 35px;
}