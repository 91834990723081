@import "./css_global.scss";

//----------------------------------------------------------------------------
// HTML & BODY & APP
//----------------------------------------------------------------------------

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 100%;
    overflow: hidden;
  }
}

body,
input,
textarea {
  color: $colorGrey1;
  font-family: "Montserrat", "Helvetica", "Verdana", sans-serif;
}

body {
  margin: 0px;
  padding: 0px;

  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  min-width: 320px;

  @extend .font_text;
  @extend .gradient;
}

.emblemSVG {
  position: fixed;
  opacity: 0.07;
  pointer-events: none;

  @media screen and (min-width: $bpL) {
    width: 1000px;
    left: -350px;
    top: -350px;
  }
  @media screen and (min-width: $bpM) and(max-width:$bpL) {
    width: 900px;
    left: -315px;
    top: -315px;
  }
  @media screen and (min-width: $bpS) and(max-width:$bpM) {
    width: 800px;
    left: -280px;
    top: -280px;
  }
  @media screen and (min-width: $bpXS) and(max-width:$bpS) {
    width: 700px;
    left: -245px;
    top: -245px;
  }
  @media screen and (min-width: 400px) and(max-width:$bpXS) {
    width: 600px;
    left: -210px;
    top: -210px;
  }
  @media screen and (max-width: 400px) {
    width: 400px;
    left: -140px;
    top: -140px;
  }

  &.pozitive > path {
    fill: $colorGrey1;
  }
  &.negative > path {
    fill: $colorBack;
  }
}

.app {
  width: 100%;
  min-height: 100%;
}

.defaultTextContainer {
  width: 100%;
  max-width: 600px;
  margin-top: 10px;
}

//----------------------------------------------------------------------------
// SIDEBAR - rightOfSidebar - HEADER - DATAZONE MAIN DIVS && MOBILE CSSs
//----------------------------------------------------------------------------

.dataZone {
  padding: 50px;
  min-height: calc(100vh - 50px);
  @media screen and (max-width: $bpXS) {
    padding: 50px 0px;
  }
}
